import React from 'react';

import Layout from '../components/Layout';
import Header from '../components/Header';
import Scroll from '../components/Scroll';
import Footer from '../components/Footer';
import Social from '../components/Social';

import demo1 from '../assets/images/demo-screen-1.jpg';
import gplay from '../assets/images/google-play-badge.svg';
import astore from '../assets/images/app-store-badge.svg';

const IndexPage = () => (
  <Layout>
    <Header />

    <header className="masthead">
      <div className="container h-100">
        <div className="row h-100">
          <div className="col-lg-7 my-auto">
            <div className="header-content mx-auto">
              <h1 className="mb-5">
                Kami menyediakan berbagai menu Ayam--Penyet, Geprek hingga Sate Jeroan. Mau Lele, Lalapan & Sambal Spesial juga ada!
              </h1>
              <Scroll type="id" element="download">
                <a href="#download" className="btn btn-outline btn-xl">
                  Pesan di MasKenzi Sekarang!
                </a>
              </Scroll>
            </div>
          </div>
          <div className="col-lg-5 my-auto">
            <div className="device-container">
              <div className="device-mockup iphone6_plus portrait white">
                <div className="device">
                  <div className="screen">
                    <img src={demo1} className="img-fluid" alt="" />
                  </div>
                  <div className="button"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>

    <section className="download bg-primary text-center" id="download">
      <div className="container">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <h2 className="section-heading">
              Bebas pilih mau pesan lewat mana!
            </h2>
            <p>
              Mau pesan Ayam Maskenzi dimanapun, pasti bakal cepat sampai kok!
            </p>
            <div className="badges">
              <a className="badge-link" href="https://gofood.co.id/english/jakarta/restaurant/ayam-penyet-mas-kenzi-karang-tengah-df4197e4-7aa9-4621-bb4c-ff1236b3b60e">
                <img src={gplay} alt="" />
              </a>
              <a className="badge-link" href="https://food.grab.com/id/en/restaurant/ayam-penyet-mas-kenzi-pedurenan-delivery/6-CY53MBLFTJVWAT">
                <img src={astore} alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="features" id="features">
      <div className="container">
        <div className="section-heading text-center">
          <h2>Nagih Ayamnya, Nagih Sambelnya</h2>
          <p className="text-muted">
            Ngga ada alasan untuk engga memilih Ayam Penyet Mas Kenzi!
          </p>
          <hr />
        </div>
        <div className="row">
          <div className="col-lg-4 my-auto">
            <div className="device-container">
              <div className="device-mockup iphone6_plus portrait white">
                <div className="device">
                  <div className="screen">
                    <img src={demo1} className="img-fluid" alt="" />
                  </div>
                  <div className="button"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8 my-auto">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6">
                  <div className="feature-item">
                    <i className="icon-screen-smartphone text-primary"></i>
                    <h3>Sambel Yang Khas</h3>
                    <p className="text-muted">
                      Ngapain juga kan beli makan di luar, tapi rasanya biasa aja!
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="feature-item">
                    <i className="icon-camera text-primary"></i>
                    <h3>Isinya Pasti Daging</h3>
                    <p className="text-muted">
                      Jauh-jauh deh beli daging tapi dapetnya banyakan tulang!
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="feature-item">
                    <i className="icon-present text-primary"></i>
                    <h3>Tradisional Tapi Sehat</h3>
                    <p className="text-muted">
                      Kombinasi tepat buat yang udah muak sama micin dan junk-food!
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="feature-item">
                    <i className="icon-lock-open text-primary"></i>
                    <h3>Pasti Terjangkau</h3>
                    <p className="text-muted">
                      Cocok buat harian, ngirit bahkan buat acara nrakteer!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="cta">
      <div className="cta-content">
        <div className="container">
          <h2>
            Ayam Penyet?
            <br />
            Mas Kenzi, Aja.
          </h2>
          <Scroll type="id" element="download">
            <a href="#download" className="btn btn-outline btn-xl">
              Order sekarang juga!
            </a>
          </Scroll>
        </div>
      </div>
      <div className="overlay"></div>
    </section>

    <section className="contact bg-primary" id="contact">
      <div className="container">
        <h2>
          Kami
          <i className="fas fa-heart"></i>
          dimampirin!
        </h2>
        <Social />
      </div>
    </section>

    <Footer />
  </Layout>
);

export default IndexPage;
